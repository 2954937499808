<template>
  <div class="flex flex-col self-center">
    <img
      src="@/assets/img/icons/warning-pink.svg"
      :alt="$t('message.somethings-gone-wrong')"
    />
    <h3 class="mx-auto mt-4">{{ $t("message.somethings-gone-wrong") }}</h3>
    <p class="text-center mt-4">{{ $t("message.please-try-again-later") }}</p>
  </div>
</template>

<script>
export default {
  name: "SomethingsGoneWrong"
};
</script>
