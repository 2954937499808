<template>
  <div>
    <div class="flex justify-evenly items-center mb-2">
      <img src="@/assets/img/logos/visa.svg" alt="" class="h-4" />
      <img src="@/assets/img/logos/mastercard.svg" alt="" class="h-7" />
      <img src="@/assets/img/logos/stripe.svg" alt="" class="h-7" />
    </div>
    <p class="text-center text-xs text-gray-600">
      Stripe is certified to PCI Service Provider Level 1. This is the most
      stringent level of certification available in the payments industry.
    </p>
  </div>
</template>

<script>
export default {
  name: "PaymentIcons"
};
</script>
