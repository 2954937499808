import gql from "graphql-tag";
import { og } from "./sharedSnippets";

export const GET_TASK_TEAM_SHARED_KENTICO = gql`
  query task($codename: String!) {
    task(codename: $codename) {
      socialShareTitle
      socialShareDescription
      socialShareImage {
        url
      }
    }
  }
`;

export const GET_TASK_KENTICO = gql`
  query task($codename: String!) {
    task(codename: $codename) {
      _system_ {
        codename
      }
      ${og}
      taskType(limit: 1) {
        items {
          _system_ {
            name
            codename
          }
        }
      }
      title
      callToAction
      coverImage {
        url
      }
      coverHeading
      coverContent
      introHeading
      introContent {
        html
      }
      introVideo {
        url
      }
      introImage {
        url
      }
      introVideoCaptions {
        items {
          file {
            url
          }
          label
          lang
        }
      }
      introContentThumbnail {
        url
      }
      introContentVideo {
        url
      }
      introContentVideoCaptions {
        items {
          file {
            url
          }
          label
          lang
        }
      }
      tweetTemplates {
        items {
          shortValue
          longValue
          _system_ {
            codename
          }
        }
      }
      completeHeading
      completeContent {
        html
      }
      completeImage {
        url
      }
      completeVideo {
        url
      }
      completeVideoCaptions {
        items {
          file {
            url
          }
          label
          lang
        }
      }
      taskShareTitle
      taskShareHashtags
    }
  }
`;
