<template>
  <div class="bg-white px-4 py-6 rounded-sm border border-gray-200">
    <h4 class="font-bold">Can you help spread the message?</h4>
    <p class="mt-4 font-body2">
      Amplify your impact by telling your friends about this team.
    </p>
    <img
      :src="shareImageUrl"
      alt="Share image"
      class="w-full rounded-md object-cover my-4 mx-auto"
    />
    <p class="font-body2">
      {{ copyInstagramMessage }}
    </p>
    <slot />
  </div>
</template>

<script>
export default {
  name: "TaskShare",
  props: {
    shareImageUrl: {
      type: String,
      required: true
    }
  },
  computed: {
    isTouchScreen() {
      return (
        "ontouchstart" in window ||
        navigator.maxTouchPoints > 0 ||
        navigator.msMaxTouchPoints > 0
      );
    },
    copyInstagramMessage() {
      if (this.isTouchScreen) {
        return "Sharing to Instagram? Long press on image above to save to your device";
      } else {
        return "Sharing to Instagram? Right click on image above to save to your device";
      }
    }
  }
};
</script>
