<template>
  <div
    class="flex w-max text-black text-sm bg-gray-200 border rounded-lg px-2 pr-1 py-1"
  >
    <LivesImpactedInfoModal v-model="showLivesImpactedInfoModal" />
    <img
      src="@/assets/img/icons/heart.svg"
      alt="coin"
      class="inline w-4 mr-1"
    />
    <div class="mx-1">
      {{ text }}
    </div>
    <InfoIcon v-if="showInfo" @infoClick="showLivesImpactedInfoModal = true" />
  </div>
</template>

<script>
import InfoIcon from "@/components/vectors/InfoIcon.vue";
import LivesImpactedInfoModal from "@/components/shared/LivesImpactedInfoModal.vue";

export default {
  name: "LivesImpactedPill",
  components: {
    InfoIcon,
    LivesImpactedInfoModal
  },
  props: {
    text: {
      required: true
    },
    showInfo: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data() {
    return {
      showLivesImpactedInfoModal: false
    };
  }
};
</script>
<style lang="postcss">
#lives-impacted-modal {
  .modal-content {
    max-width: var(--desktop-post-width);
    @apply sm:h-3/5;
  }
}
</style>
