<template>
  <button
    class="w-8 h-8 flex justify-center items-center arrow-box outline-none focus:outline-none"
    @click="$emit('click')"
    :class="[
      type,
      {
        'h-14 w-14 bg-gray-400 bg-opacity-60 rounded-full':
          !isDesktop && type == 'white'
      }
    ]"
  >
    <img :src="imagePath()" alt="Exit" />
  </button>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "backButton",
  props: {
    type: {
      type: String,
      required: false,
      default: "base"
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    imageType() {
      // blue
      if (this.type === "base") {
        return "";
      } else if (this.type === "black") {
        return "-black";
      } else if (this.type === "white") {
        return "-white";
      }
      return "";
    }
  },
  methods: {
    imagePath() {
      return require(`@/assets/img/icons/exit-icon${this.imageType}.svg`);
    }
  }
};
</script>
