<template>
  <div id="payment-processing-modal">
    <BaseModal
      size="h-full"
      :bgColor="'bg-white'"
      :showCloseButton="false"
      v-model="showModal"
    >
      <h3>{{ $t("message.processing-payment") }}</h3>
      <div class="w-12 h-12 my-12 overflow-hidden">
        <Spinner class="sm-button-dark" />
      </div>
      <p class="text-center">
        {{ $t("message.please-leave-this-screen-open-until-complete") }}
      </p>
      <PaymentIcons
        class="absolute sm:relative bottom-0 w-4/5 sm:w-full mb-6 sm:mb-0 sm:mt-6"
      />
    </BaseModal>
  </div>
</template>
<script>
import Spinner from "@/components/shared/Spinner.vue";
import PaymentIcons from "@/components/shared/PaymentIcons.vue";
export default {
  components: {
    Spinner,
    PaymentIcons
  },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showModal: this.value
    };
  },
  watch: {
    showModal(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.showModal = val;
    }
  }
};
</script>
<style lang="postcss">
#payment-processing-modal {
  @media screen and (min-width: 640px) {
    .modal-content {
      max-width: var(--desktop-post-width);
      height: auto;

      @apply rounded-md overflow-hidden p-14 items-center;
    }
  }

  .modal-content {
    @apply justify-center items-center rounded-none;
  }

  .header {
    display: none;
  }
}
</style>
