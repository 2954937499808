<template>
  <div class="flex items-center">
    <Checkbox
      id="task-completed"
      v-model="taskStatus"
      v-on="$listeners"
      :disabled="alreadyCompleted"
    />
    <label
      class="font-body ml-4 cursor-pointer"
      :class="{ 'text-gray-700': alreadyCompleted }"
      for="task-completed"
    >
      {{ $t("message.i-have-completed-this-task") }}
    </label>
  </div>
</template>

<script>
import Checkbox from "@/components/form/Checkbox.vue";

export default {
  name: "TaskCompletedCheckbox",
  components: {
    Checkbox
  },
  props: {
    alreadyCompleted: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  data() {
    return {
      taskStatus: this.alreadyCompleted
    };
  }
};
</script>
