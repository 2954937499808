<template>
  <div class="h-full">
    <template v-if="appsyncTask && kenticoTask">
      <div
        class="task grid md:grid-cols-2 md:gap-4 md:mt-6"
        :class="{
          'h-full': !isDesktop
        }"
      >
        <BackButton
          v-if="isDesktop"
          id="back-button"
          @click="goBack()"
          :text="appsyncTask.team.name"
          type="dark"
          class="col-span-2 w-auto mr-auto"
        />
        <div v-if="!isDesktop" class="sticky top-12">
          <div class="absolute w-full flex flex-row justify-end px-4">
            <CloseButton @click="goBack" type="white" />
          </div>
        </div>
        <div class="main-image">
          <VideoRender
            :key="videoUrl"
            :videoUrl="videoUrl"
            :captions="videoCaptions"
            :coverImgUrl="imageUrl"
            class="md:rounded-md"
          >
            <div
              class="h-full bg-cover bg-center md:rounded-md"
              :style="`background-image: url('${imageUrl}');`"
            />
          </VideoRender>
        </div>
        <div
          class="main-info md:rounded-md bg-gray-800 text-white relative"
          :class="{
            'desktop-single-pane-height': isDesktop && !showImage
          }"
        >
          <img :src="imageUrl" class="absolute top-0 left-0 h-full bg-blur" />
          <div
            class="flex flex-col p-4 md:p-14 h-full justify-around relative"
            :class="{
              'w-1/2 mx-auto': !showImage && isDesktop,
              'pb-0': !isDesktop
            }"
          >
            <div
              v-if="status !== COMPLETE && contentVideoUrl"
              class="h-72 sm:h-48 mb-4"
            >
              <VideoRender
                :videoUrl="contentVideoUrl"
                :coverImgUrl="contentVideoThumbnailUrl"
                :captions="contentVideoCaptions"
                :nativeControls="true"
                :key="contentVideoUrl"
              >
                <div
                  class="h-full bg-cover bg-center md:rounded-md"
                  :style="
                    `background-image: url('${contentVideoThumbnailUrl}');`
                  "
                />
              </VideoRender>
            </div>
            <LivesImpactedPill
              v-if="status === INIT"
              :text="formatPointsOnCompletion(appsyncTask.pointsOnCompletion)"
              class="mb-4 mx-auto"
              :class="{ 'order-first': !isComplete }"
            />
            <h1 class="text-center mb-4">
              {{ headerTitle }}
            </h1>
            <div v-if="status === INIT" class="flex flex-col mb-4">
              <p
                class="font-body2 text-center mb-4"
                v-html="kenticoTask.introContent.html"
              />
              <label for="commit-info mb-1">What will you commit to? *</label>
              <InputWrapper
                name="commit-info"
                id="commit-info"
                class="text-black"
                type="textarea"
                v-model="commitmentText"
              />
              <div
                v-if="errors && errors.commitment"
                class="bg-red-50 text-red-900 p-2 rounded-sm mt-2"
              >
                {{ errors.commitment }}
              </div>
              <h4 class="mt-7">Sign here *</h4>
              <p class="font-body2 mb-1">
                Make this personal commitment to yourself by signing your name.
              </p>
              <div class="bg-white rounded-sm pb-4 px-2">
                <div class="border-b-2 border-black relative">
                  <VueSignaturePad
                    width="100%"
                    height="125px"
                    ref="signaturePad"
                  />
                  <button
                    class="absolute right-0 bottom-0 text-black font-extrabold"
                    @click="clearSignature"
                  >
                    x
                  </button>
                </div>
              </div>
              <div
                v-if="errors && errors.signature"
                class="bg-red-50 text-red-900 p-2 rounded-sm mt-2"
              >
                {{ errors.signature }}
              </div>
              <TaskCallToAction
                :buttonEnabled="true"
                :actionButtonBusy="actionButtonBusy"
                buttonText="commit"
                class="mt-3"
                @handleTaskCTA="handleTaskCTA"
              />
              <p class="font-tiny mx-auto">
                * Required
              </p>
            </div>
            <div v-if="status === COMPLETE">
              <p
                class="font-body2 text-center mb-4"
                v-html="kenticoTask.completeContent.html"
              />
              <LivesImpactedPill
                :text="formatPointsOnCompletion(appsyncTask.pointsOnCompletion)"
                class="mb-4 mx-auto"
                :class="{ 'order-first': !isComplete }"
              />
              <TaskShare :shareImageUrl="shareImageUrl" class="mb-8 text-black">
                <SocialShareGeneric
                  v-if="teamUrl && shareData"
                  class="mt-8 z-10"
                  :url="teamUrl"
                  :shareText="
                    kenticoTask.taskShareTitle !== ''
                      ? kenticoTask.taskShareTitle
                      : 'share'
                  "
                  :data="shareData"
                  :hashtags="
                    kenticoTask.taskShareHashtags !== ''
                      ? kenticoTask.taskShareHashtags
                      : 'share'
                  "
                  type="task-completed"
                />
              </TaskShare>
              <button class="btn btn-default w-full mb-4" @click="goBack()">
                Done
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <TaskPageSkeleton />
    </template>
  </div>
</template>
<script>
import BackButton from "@/components/shared/BackButton.vue";
import CloseButton from "@/components/shared/CloseButton.vue";
import InputWrapper from "@/components/form/InputWrapper.vue";
import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
import TaskCallToAction from "./TaskCallToAction.vue";
import TaskPageSkeleton from "@/components/skeletons/TaskPageSkeleton.vue";
import TaskShare from "./TaskShare.vue";
import VideoRender from "@/components/shared/VideoRender.vue";
import { formatPointsOnCompletion } from "@/utils/basicHelpers.js";
import { KENTICO_GET_TEAM_SHORT } from "@/kentico-api/teams.js";
import { TASK_ACTION_READ } from "@/graphql/queries/user/userMutations.js";
import { VueSignaturePad } from "vue-signature-pad";
import { mapGetters } from "vuex";
import { GET_PROFILE_SUPPORT_FORM } from "@/graphql/queries/user/userQueries.js";
const COMPLETE = "complete";
const INIT = "init";
import {
  zendeskEnabled,
  zendeskCreateRequest
} from "@/utils/zendeskSupportRequest.js";
export default {
  components: {
    CloseButton,
    VueSignaturePad,
    TaskShare,
    SocialShareGeneric,
    TaskCallToAction,
    InputWrapper,
    TaskPageSkeleton,
    BackButton,
    LivesImpactedPill,
    VideoRender
  },
  props: {
    appsyncTask: {
      type: Object,
      required: true
    },
    kenticoTask: {
      type: Object,
      required: true
    },
    metaData: {
      type: Object,
      required: true
    },
    shareData: {
      type: Object,
      required: true
    },
    isMember: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      isZendeskApiEnabled: zendeskEnabled(),
      actionButtonBusy: false,
      commitmentText: "",
      status: INIT,
      errors: {},
      INIT,
      COMPLETE
    };
  },
  computed: {
    ...mapGetters("auth", ["isLogged"]),
    headerTitle() {
      if (this.status === COMPLETE && this.kenticoTask.completeHeading !== "") {
        return this.kenticoTask.completeHeading;
      }
      return this.kenticoTask.introHeading;
    },
    teamTitle() {
      if (!this.kenticoTeam) return "";
      return this.kenticoTeam.title;
    },
    contentVideoUrl() {
      return this.kenticoTask.introContentVideo?.url || "";
    },
    loggedUser() {
      return this.$store.getters["auth/currentUser"];
    },
    teamUrl() {
      if (!this.appsyncTask?.team?.slug) return false;

      let props = this.$router.resolve({
        name: "TeamPage",
        params: {
          slug: this.appsyncTask?.team?.slug || ""
        },
        query: {
          sharedFromTask: this.appsyncTask.kenticoCodename
        }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    },
    isComplete() {
      return this.appsyncTask.progress.status === COMPLETE;
    },
    isDesktop() {
      return this.$store.getters["general/isDesktop"];
    },
    imageUrl() {
      if (this.status == COMPLETE && this.kenticoTask.completeImage.url) {
        return this.kenticoTask.completeImage.url;
      } else if (this.kenticoTask.introImage.url) {
        return this.kenticoTask.introImage.url;
      }
      return this.kenticoTask.coverImage.url || "";
    },
    showImage() {
      return this.imageUrl.length > 0 || this.videoUrl.length > 0;
    },
    videoUrl() {
      if (this.status == COMPLETE && this.kenticoTask.completeVideo.url) {
        return this.kenticoTask.completeVideo.url;
      } else if (this.kenticoTask.introVideo.url) {
        return this.kenticoTask.introVideo.url;
      }
      return "";
    },
    videoCaptions() {
      let captions = [];

      if (this.status == COMPLETE && this.kenticoTask.completeVideo.url) {
        captions = this.kenticoTask.completeVideoCaptions.items;
      } else if (this.kenticoTask.introVideo.url) {
        captions = this.kenticoTask.introVideoCaptions.items;
      }
      return captions.map(caption => {
        return {
          url: caption.file.url,
          lang: caption.lang,
          label: caption.label
        };
      });
    },
    isNotStarted() {
      return this.appsyncTask.progress.status === "not_started";
    },
    shareImageUrl() {
      if (this.kenticoTask._openGraph?.ogImage?.url !== "")
        return this.kenticoTask._openGraph.ogImage.url;

      return this.imageUrl;
    }
  },
  apollo: {
    profile: {
      query: GET_PROFILE_SUPPORT_FORM,
      update(data) {
        return data.getMyProfile;
      },
      fetchPolicy: "no-cache",
      variables() {
        return {
          screenName: this.$store.getters["auth/currentUser"]?.attributes
            .screenName
        };
      },
      skip() {
        return !this.isLogged;
      }
    },
    kenticoTeam: {
      client: "kenticoClient",
      query: KENTICO_GET_TEAM_SHORT,
      variables() {
        return {
          codename: this.$route.params.slug.replaceAll("-", "_")
        };
      },
      update(data) {
        return data.team;
      },
      fetchPolicy: "no-cache"
    }
  },
  methods: {
    formatPointsOnCompletion,
    goBack() {
      this.$router.push({
        name: "TeamPage"
      });
    },
    async completeTaskZendesk() {
      if (!this.isZendeskApiEnabled) return;

      const userEmail =
        this.profile?.email && this.profile.email !== ""
          ? `Email: ${this.profile.email}`
          : "Email: not provided";
      return await zendeskCreateRequest({
        name: this.loggedUser.attributes.displayName,
        email: null,
        subject: `Commitment - ${this.teamTitle} - ${this.kenticoTask.title}`,
        comment: `${this.commitmentText} - ${userEmail}`,
        type: "task",
        requestType: "Commitment",
        requestContext: this.appsyncTask.kenticoCodename
      });
    },
    async completeTask() {
      return await this.$apollo
        .mutate({
          mutation: TASK_ACTION_READ,
          variables: {
            teamId: this.appsyncTask.teamId,
            taskId: this.appsyncTask.id
          },
          update: async (data, data2) => {
            if (data2.data.taskStart == true) {
              this.completeTaskTransition();
            } else {
              console.error("taskStart request failed");
            }
          }
        })
        .catch(err => {
          console.error(err);
        });
    },
    completeTaskTransition() {
      this.status = COMPLETE;
    },
    validate() {
      let valid = true;
      if (
        this.commitmentText === "" ||
        this.commitmentText.replaceAll(" ", "") === ""
      ) {
        this.$set(
          this.errors,
          "commitment",
          "Please add your commitment information"
        );
        valid = false;
      }
      if (this.$refs.signaturePad.isEmpty()) {
        this.$set(this.errors, "signature", "Please sign your commitment");
        valid = false;
      }
      return valid;
    },
    async handleTaskCTA() {
      if (this.actionButtonBusy) return;
      if (!this.validate()) return;
      this.actionButtonBusy = true;

      if (this.isLogged) {
        if (this.isComplete) {
          await this.completeTaskZendesk().catch(e => {
            console.error(e);
          });
          this.completeTaskTransition();
        } else if (this.isMember) {
          await this.completeTaskZendesk().catch(e => {
            console.error(e);
          });
          await this.completeTask();
        } else {
          this.$emit("showJoinTeam");
        }
      } else {
        this.$router.push({ name: "SignInPage" });
      }
      this.actionButtonBusy = false;
    },
    clearSignature() {
      this.$refs.signaturePad.clearSignature();
    }
  }
};
</script>
<style lang="postcss" scoped>
.bg-blur {
  filter: blur(25px);
  -webkit-filter: blur(25px);
  opacity: 0.6;
}
.task {
  grid-template-rows: min-content;
}

.main-image {
  height: 33rem;
}

@media screen and (min-width: 768px) {
  .main-image {
    height: 561px;
  }
}
</style>
