<template>
  <div id="join-team-modal">
    <BaseModal size="h-full" :bgColor="'bg-white'" v-model="showModal">
      <Logo color="pink" class="mx-auto mt-4" />
      <div class="mx-10 text-center">
        <label
          for="modal-message"
          class="block text-lg font-semibold m-4"
          v-html="textLabel"
        >
        </label>
        <p id="modal-message" class="h-20" v-html="textMessage"></p>
        <button
          @click="$emit(!isMember ? 'joinTeam' : 'close')"
          class="btn w-60 bg-black text-white border border-black outline-none focus:outline-none flex text-center justify-center items-center px-6 mt-10 mb-8 mx-auto"
        >
          <span class="mx-auto" v-if="!loading">
            {{ textButton }}
          </span>
          <div class="loading w-6 h-6" v-else>
            <Spinner class="sm-button" />
          </div>
        </button>
      </div>
    </BaseModal>
  </div>
</template>

<script>
import Logo from "@/components/Logo.vue";
import Spinner from "@/components/shared/Spinner.vue";

export default {
  name: "TaskJoinTeamModal",
  components: {
    Logo,
    Spinner
  },
  props: {
    value: {
      type: Boolean,
      required: true
    },
    isMember: {
      type: Boolean,
      required: true
    },
    teamName: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    textLabel() {
      if (this.isMember) {
        return `Welcome to team ${this.teamName}!`;
      } else {
        return "Join the Team?";
      }
    },
    textMessage() {
      if (this.isMember) {
        return `We're glad you're here.`;
      } else {
        return `You must be a member of this team before you can do tasks. Would you
          like to join <span class="font-semibold">${this.teamName}</span>?`;
      }
    },
    textButton() {
      if (this.isMember) {
        return "Close";
      } else {
        return "Join the team";
      }
    }
  },
  data() {
    return {
      showModal: this.value,
      actionButtonBusy: false
    };
  },
  watch: {
    showModal(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.showModal = val;
    }
  }
};
</script>

<style lang="postcss">
#join-team-modal {
  .modal-content {
    max-width: var(--desktop-post-width);
    height: auto;

    @apply rounded-md;
  }
}
</style>
