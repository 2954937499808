<template>
  <div class="btn-cta-cont" :class="{ sticky: !isDesktop }">
    <button
      :disabled="!buttonEnabled"
      @click="$emit('handleTaskCTA')"
      @blur="$emit('blur')"
      class="btn w-full btn-default mb-8 overflow-hidden"
    >
      <div class="loading w-6 h-6" v-if="actionButtonBusy">
        <Spinner class="sm-button" />
      </div>
      <span v-else class="mx-auto" v-html="buttonText"></span>
    </button>
  </div>
</template>

<script>
import Spinner from "@/components/shared/Spinner.vue";
import { mapGetters } from "vuex";
export default {
  name: "TaskCallToAction",
  components: {
    Spinner
  },
  props: {
    actionButtonBusy: {
      type: Boolean,
      required: true
    },
    buttonText: {
      type: String,
      required: true
    },
    buttonEnabled: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    ...mapGetters("general", ["isDesktop"])
  }
};
</script>

<style lang="postcss" scoped>
.btn-cta-cont {
  backdrop-filter: blur(2px);

  :disabled {
    @apply bg-gray-400 border-none cursor-default;
  }
}
</style>
