import { isValidEmailFormat } from "@/utils/basicHelpers";

export const validateField = (object, field, setError, unsetError) => {
  const value = object[field.name].value;
  unsetError(field.name);
  for (let rule of field.validators || []) {
    switch (rule?.type) {
      case "empty":
        if (Validator.String.isEmpty(value)) {
          setError(field.name, "message.formValidation.empty");
          return false;
        }
        break;
      case "emailFormat":
        if (Validator.String.isInvalidEmailFormat(value)) {
          setError(field.name, "message.formValidation.invalid_email");
          return false;
        }
        break;
      case "minLength":
        if (Validator.String.isTooShort(value, rule.value)) {
          setError(field.name, "message.formValidation.too_short", {
            val: rule.value
          });
          return false;
        }
        break;
      case "maxLength":
        if (Validator.String.isTooLong(value, rule.value)) {
          setError(field.name, "message.formValidation.too_long", {
            val: rule.value
          });
          return false;
        }
        break;
    }
  }
  return true;
};

/*
`object` is a hash of fields with a .value
`fieldset` is a list of fields and validation rules
`setError` is called when a field validation fails
`unsetError` is called when a field validation passes
*/
export const validateObject = (object, fieldset, setError, unsetError) => {
  let errorCount = 0;
  for (let field of fieldset) {
    if (!validateField(object, field, setError, unsetError)) {
      errorCount += 1;
    }
  }
  return errorCount === 0;
};

export const Validator = {
  String: {
    isEmpty: $ => {
      return String($).replaceAll(" ", "") === "";
    },
    isInvalidEmailFormat: $ => {
      return !isValidEmailFormat($);
    },
    isTooShort: ($, min) => {
      return String($, min).length < min;
    },
    isTooLong: ($, max) => {
      return String($, max).length > max;
    }
  },
  Number: {
    isInvalid: $ => {
      return Validator.String.isEmpty($) || isNaN(Number($));
    },
    isTooSmall: ($, min) => {
      return Number($) < min;
    },
    isTooBig: ($, max) => {
      return Number($) > max;
    }
  }
};
