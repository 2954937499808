<template>
  <div class="h-full">
    <template v-if="task">
      <div
        class="task grid grid-cols-1 md:gap-4 md:mt-6"
        :class="{
          'md:grid-cols-2': showImage,
          'h-full': !isDesktop
        }"
      >
        <BackButton
          v-if="isDesktop"
          id="back-button"
          @click="goBack()"
          :text="task.team.name"
          type="dark"
          class="col-span-2 w-auto mr-auto"
        />
        <div v-if="!isDesktop" class="sticky top-0 z-50">
          <div class="absolute w-full flex flex-row justify-end pt-12 px-4">
            <CloseButton @click="goBack" type="white" />
          </div>
        </div>
        <div class="main-image">
          <VideoRender
            :key="videoUrl"
            :videoUrl="videoUrl"
            :captions="videoCaptions"
            :coverImgUrl="imageUrl"
            class="md:rounded-md"
          >
            <div
              class="h-full bg-cover bg-center md:rounded-md"
              :style="`background-image: url('${imageUrl}');`"
            />
          </VideoRender>
        </div>
        <div
          class="main-info md:rounded-md"
          :class="{
            'bg-white': isDesktop,
            'desktop-single-pane-height': isDesktop && !showImage
          }"
        >
          <div
            class="flex flex-col p-4 md:p-6 h-full justify-around"
            :class="{
              'w-1/2 mx-auto': !showImage && isDesktop,
              'pb-0': !isDesktop
            }"
          >
            <h3
              class="block mb-4"
              :class="{ 'text-center': isComplete && !completedPreviously }"
            >
              {{ heading }}
            </h3>
            <div
              v-if="(!isComplete || completedPreviously) && contentVideoUrl"
              class="h-72 sm:h-48 mb-4"
            >
              <VideoRender
                :videoUrl="contentVideoUrl"
                :coverImgUrl="contentVideoThumbnailUrl"
                :captions="contentVideoCaptions"
                :nativeControls="true"
                :key="contentVideoUrl"
              >
                <div
                  class="h-full bg-cover bg-center md:rounded-md"
                  :style="
                    `background-image: url('${contentVideoThumbnailUrl}');`
                  "
                />
              </VideoRender>
            </div>

            <p
              v-html="parsedContent(content)"
              class="mb-4 kentico-content"
              :class="{ 'text-center': isComplete && !completedPreviously }"
            ></p>
            <TweetBox
              v-if="isTweetTask && (!isComplete || completedPreviously)"
              :actionButtonBusy="actionButtonBusy"
              :buttonText="kenticoTask.callToAction"
              :tweetTemplates="tweetTemplates"
              :isMember="isMember"
              :isLogged="isLogged"
              @twitterOpened="twitterOpened"
              @showJoinTeam="$emit('showJoinTeam')"
            >
            </TweetBox>
            <h4
              class="text-center mb-4"
              v-if="isComplete && !completedPreviously"
            >
              {{ $t("message.your-impact") }}
            </h4>
            <LivesImpactedPill
              :text="formatPointsOnCompletion(appsyncTask.pointsOnCompletion)"
              class="mb-4 mx-auto"
              :class="{ 'order-first': !isComplete || completedPreviously }"
            />
            <TaskShare
              v-if="isComplete && !completedPreviously"
              :shareImageUrl="metaData.imageUrl || imageUrl"
              class="mb-8"
            >
              <SocialShareGeneric
                v-if="teamUrl && shareData"
                class="mt-8 z-10"
                :url="teamUrl"
                :shareText="
                  kenticoTask.taskShareTitle !== ''
                    ? kenticoTask.taskShareTitle
                    : 'share'
                "
                :data="shareData"
                :hashtags="
                  kenticoTask.taskShareHashtags !== ''
                    ? kenticoTask.taskShareHashtags
                    : 'share'
                "
                type="task-completed"
              />
            </TaskShare>
            <div v-if="!isTweetTask" class="bottom-0 mt-auto">
              <TaskCompletedCheckbox
                v-if="viewMode == 'incomplete' || completedPreviously"
                class="mb-5"
                :alreadyCompleted="completedPreviously"
                @input="taskCompletionConfirmed = !taskCompletionConfirmed"
              />
              <TaskCallToAction
                :buttonEnabled="!completedPreviously && taskCompletionConfirmed"
                :actionButtonBusy="actionButtonBusy"
                :buttonText="buttonText"
                @handleTaskCTA="handleTaskCTA"
              />
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <TaskPageSkeleton />
    </template>
  </div>
</template>

<script>
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
import TaskPageSkeleton from "@/components/skeletons/TaskPageSkeleton.vue";
import BackButton from "@/components/shared/BackButton.vue";
import CloseButton from "@/components/shared/CloseButton.vue";
import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";
import VideoRender from "@/components/shared/VideoRender.vue";
import TaskShare from "./TaskShare.vue";
import TaskCompletedCheckbox from "./TaskCompletedCheckbox.vue";
import TaskCallToAction from "./TaskCallToAction.vue";
import { scrollToElement } from "@/utils/basicHelpers";
import {
  TASK_ACTION_PLEDGE,
  TASK_ACTION_READ,
  TASK_ACTION_WATCH
} from "@/graphql/queries/user/userMutations.js";
import { mapGetters } from "vuex";
import { formatPointsOnCompletion } from "@/utils/basicHelpers.js";
import TweetBox from "./TweetBox.vue";

export default {
  name: "TaskStandard",
  components: {
    VideoRender,
    BackButton,
    CloseButton,
    LivesImpactedPill,
    TaskShare,
    TaskCompletedCheckbox,
    TaskCallToAction,
    TaskPageSkeleton,
    SocialShareGeneric,
    TweetBox
  },
  props: {
    appsyncTask: {
      type: Object,
      required: true
    },
    kenticoTask: {
      type: Object,
      required: true
    },
    metaData: {
      type: Object,
      required: true
    },
    isMember: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      actionButtonBusy: false,
      completedPreviously: this.appsyncTask.progress.status == "complete",
      taskCompletionConfirmed: false,
      tweetTemplateName: ""
    };
  },
  computed: {
    ...mapGetters("auth", ["isLogged"]),
    task() {
      if (!this.appsyncTask || !this.kenticoTask) return null;
      return {
        ...this.appsyncTask,
        ...this.kenticoTask
      };
    },
    teamUrl() {
      if (!this.appsyncTask?.team?.slug) return false;

      let props = this.$router.resolve({
        name: "TeamPage",
        params: {
          slug: this.appsyncTask?.team?.slug || ""
        },
        query: {
          sharedFromTask: this.appsyncTask.kenticoCodename
        }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    },
    shareData() {
      if (!this.appsyncTask?.id || !this.appsyncTask?.team?.slug) return false;

      return {
        task: {
          taskId: this.appsyncTask?.id || "",
          teamSlug: this.appsyncTask?.team?.slug || ""
        }
      };
    },
    heading() {
      if (this.viewMode == "incomplete" || this.completedPreviously)
        return this.task.introHeading;
      else return this.task.completeHeading;
    },
    content() {
      if (this.viewMode == "incomplete" || this.completedPreviously)
        return this.task.introContent.html;
      else return this.task.completeContent.html;
    },
    contentVideoThumbnailUrl() {
      return this.task.introContentThumbnail?.url || "";
    },
    contentVideoUrl() {
      return this.task.introContentVideo?.url || "";
    },
    contentVideoCaptions() {
      return this.task.introContentVideoCaptions.items.map(caption => {
        return {
          url: caption.file.url,
          lang: caption.lang,
          label: caption.label
        };
      });
    },
    buttonText() {
      if (this.viewMode == "incomplete") return this.task.callToAction;
      else if (this.completedPreviously)
        return this.$t("message.task-completed");
      else return this.$t("message.done");
    },
    showImage() {
      return this.imageUrl.length > 0 || this.videoUrl.length > 0;
    },
    imageUrl() {
      // coverImageUrl is always present.
      // introImageUrl and completeImageUrl are optional and use coverImageUrl as a fallback.
      if (
        this.viewMode == "complete" &&
        !this.completedPreviously &&
        this.task.completeImage.url
      ) {
        return this.task.completeImage.url;
      } else if (this.task.introImage.url) {
        return this.task.introImage.url;
      }
      return this.task.coverImage.url;
    },
    videoUrl() {
      if (this.isNotStarted || this.completedPreviously) {
        return this.task.introVideo?.url || "";
      } else if (this.isComplete) {
        return this.task.completeVideo?.url || "";
      }
      return "";
    },
    videoCaptions() {
      let captions = [];
      if (this.isNotStarted || this.completedPreviously) {
        captions = this.task.introVideoCaptions;
      } else if (this.isComplete) {
        captions = this.task.completeVideoCaptions;
      }
      return captions.items.map(caption => {
        return {
          url: caption.file.url,
          lang: caption.lang,
          label: caption.label
        };
      });
    },
    isNotStarted() {
      return this.appsyncTask.progress.status === "not_started";
    },
    isInProgress() {
      return this.appsyncTask.progress.status === "in_progress";
    },
    isComplete() {
      return this.appsyncTask.progress.status === "complete";
    },
    viewMode() {
      if (this.isNotStarted || this.isInProgress) {
        return "incomplete";
      }
      return "complete";
    },
    showCTA() {
      if (
        (this.viewMode == "incomplete" && this.isMember) ||
        (this.viewMode == "complete" && this.isMember && this.isComplete)
      ) {
        return true;
      } else {
        return false;
      }
    },
    isDesktop() {
      return this.$store.getters["general/isDesktop"];
    },
    kenticoTaskType() {
      return this.kenticoTask?.taskType?.items[0]?._system_?.name || null;
    },
    isTweetTask() {
      return this.kenticoTaskType === "Tweet";
    },
    tweetTemplates() {
      return (
        this.kenticoTask?.tweetTemplates?.items.map(item => {
          return { label: item.shortValue, value: item.longValue };
        }) || []
      );
    }
  },
  methods: {
    formatPointsOnCompletion,
    handleTaskCTA() {
      if (this.isLogged) {
        if (this.isComplete) {
          this.$router.push({ name: "TeamPage" });
        } else if (this.isMember) {
          this.taskStart();
        } else {
          this.$emit("showJoinTeam");
        }
      } else {
        // redirect to login screen
        this.$router.push({ name: "SignInPage" });
      }
    },
    twitterOpened({ template }) {
      this.tweetTemplateName = template;
      if (!this.isComplete) {
        this.taskStart();
      } else {
        this.completeTaskTransition();
      }
    },
    taskStart() {
      this.actionButtonBusy = true;
      this.$apollo
        .mutate({
          mutation: this.getMutation(),
          variables: {
            teamId: this.appsyncTask.teamId,
            taskId: this.appsyncTask.id
          },
          update: (data, data2) => {
            if (data2.data.taskStart == true) {
              this.completeTaskTransition();
            } else {
              console.error("taskStart request failed");
            }
          }
        })
        .then(() => {
          this.actionButtonBusy = false;
        })
        .catch(err => {
          console.error(err);
          this.actionButtonBusy = false;
        });
    },
    completeTaskTransition() {
      const metadata = {
        id: this.appsyncTask.id,
        title: this.task.coverContent,
        type: this.kenticoTaskType,
        tweetTemplate: this.tweetTemplateName
      };
      this.$analytics.startTask(metadata);
      this.$analytics.completeTask(metadata);
      this.appsyncTask.progress.status = "complete";
      // scroll to top on mobile
      if (!this.isDesktop) scrollToElement("video-render");
    },
    getMutation() {
      switch (this.appsyncTask.taskType) {
        case "PledgeTask":
          return TASK_ACTION_PLEDGE;
        case "ReadTask":
          return TASK_ACTION_READ;
        case "WatchTask":
          return TASK_ACTION_WATCH;
        default:
          return "";
      }
    },
    parsedContent(src) {
      return src.replace(/\n/g, "<br />");
    },
    goBack() {
      // on pledge tasks go back always goes to team page
      this.$router.push({
        name: "TeamPage"
      });
    }
  }
};
</script>

<style lang="postcss" scoped>
.task {
  grid-template-rows: min-content;
}

ul li {
  display: inline;
}

.main-image {
  height: 33rem;
}

.btn-cta-cont {
  backdrop-filter: blur(2px);
}

@media screen and (min-width: 768px) {
  .main-image {
    height: 561px;
  }
}
</style>
