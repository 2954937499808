import { render, staticRenderFns } from "./TweetBox.vue?vue&type=template&id=7dda8e39&scoped=true&"
import script from "./TweetBox.vue?vue&type=script&lang=js&"
export * from "./TweetBox.vue?vue&type=script&lang=js&"
import style0 from "./TweetBox.vue?vue&type=style&index=0&id=7dda8e39&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dda8e39",
  null
  
)

export default component.exports