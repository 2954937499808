var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h-full"},[(_vm.task)?[_c('div',{staticClass:"task grid grid-cols-1 md:gap-4 md:mt-6",class:{
        'md:grid-cols-2': _vm.showImage,
        'h-full': !_vm.isDesktop
      }},[(_vm.isDesktop)?_c('BackButton',{staticClass:"col-span-2 w-auto mr-auto",attrs:{"id":"back-button","text":_vm.task.team.name,"type":"dark"},on:{"click":function($event){return _vm.goBack()}}}):_vm._e(),(!_vm.isDesktop)?_c('div',{staticClass:"sticky top-0 z-50"},[_c('div',{staticClass:"absolute w-full flex flex-row justify-end pt-12 px-4"},[_c('CloseButton',{attrs:{"type":"white"},on:{"click":_vm.goBack}})],1)]):_vm._e(),_c('div',{staticClass:"main-image"},[_c('VideoRender',{key:_vm.videoUrl,staticClass:"md:rounded-md",attrs:{"videoUrl":_vm.videoUrl,"captions":_vm.videoCaptions,"coverImgUrl":_vm.imageUrl}},[_c('div',{staticClass:"h-full bg-cover bg-center md:rounded-md",style:(("background-image: url('" + _vm.imageUrl + "');"))})])],1),_c('div',{staticClass:"main-info md:rounded-md",class:{
          'bg-white': _vm.isDesktop,
          'desktop-single-pane-height': _vm.isDesktop && !_vm.showImage
        }},[_c('div',{staticClass:"flex flex-col p-4 md:p-6 h-full justify-around",class:{
            'w-1/2 mx-auto': !_vm.showImage && _vm.isDesktop,
            'pb-0': !_vm.isDesktop
          }},[_c('h3',{staticClass:"block mb-4",class:{ 'text-center': _vm.isComplete && !_vm.completedPreviously }},[_vm._v(" "+_vm._s(_vm.heading)+" ")]),((!_vm.isComplete || _vm.completedPreviously) && _vm.contentVideoUrl)?_c('div',{staticClass:"h-72 sm:h-48 mb-4"},[_c('VideoRender',{key:_vm.contentVideoUrl,attrs:{"videoUrl":_vm.contentVideoUrl,"coverImgUrl":_vm.contentVideoThumbnailUrl,"captions":_vm.contentVideoCaptions,"nativeControls":true}},[_c('div',{staticClass:"h-full bg-cover bg-center md:rounded-md",style:(("background-image: url('" + _vm.contentVideoThumbnailUrl + "');"))})])],1):_vm._e(),_c('p',{staticClass:"mb-4 kentico-content",class:{ 'text-center': _vm.isComplete && !_vm.completedPreviously },domProps:{"innerHTML":_vm._s(_vm.parsedContent(_vm.content))}}),(_vm.isTweetTask && (!_vm.isComplete || _vm.completedPreviously))?_c('TweetBox',{attrs:{"actionButtonBusy":_vm.actionButtonBusy,"buttonText":_vm.kenticoTask.callToAction,"tweetTemplates":_vm.tweetTemplates,"isMember":_vm.isMember,"isLogged":_vm.isLogged},on:{"twitterOpened":_vm.twitterOpened,"showJoinTeam":function($event){return _vm.$emit('showJoinTeam')}}}):_vm._e(),(_vm.isComplete && !_vm.completedPreviously)?_c('h4',{staticClass:"text-center mb-4"},[_vm._v(" "+_vm._s(_vm.$t("message.your-impact"))+" ")]):_vm._e(),_c('LivesImpactedPill',{staticClass:"mb-4 mx-auto",class:{ 'order-first': !_vm.isComplete || _vm.completedPreviously },attrs:{"text":_vm.formatPointsOnCompletion(_vm.appsyncTask.pointsOnCompletion)}}),(_vm.isComplete && !_vm.completedPreviously)?_c('TaskShare',{staticClass:"mb-8",attrs:{"shareImageUrl":_vm.metaData.imageUrl || _vm.imageUrl}},[(_vm.teamUrl && _vm.shareData)?_c('SocialShareGeneric',{staticClass:"mt-8 z-10",attrs:{"url":_vm.teamUrl,"shareText":_vm.kenticoTask.taskShareTitle !== ''
                  ? _vm.kenticoTask.taskShareTitle
                  : 'share',"data":_vm.shareData,"hashtags":_vm.kenticoTask.taskShareHashtags !== ''
                  ? _vm.kenticoTask.taskShareHashtags
                  : 'share',"type":"task-completed"}}):_vm._e()],1):_vm._e(),(!_vm.isTweetTask)?_c('div',{staticClass:"bottom-0 mt-auto"},[(_vm.viewMode == 'incomplete' || _vm.completedPreviously)?_c('TaskCompletedCheckbox',{staticClass:"mb-5",attrs:{"alreadyCompleted":_vm.completedPreviously},on:{"input":function($event){_vm.taskCompletionConfirmed = !_vm.taskCompletionConfirmed}}}):_vm._e(),_c('TaskCallToAction',{attrs:{"buttonEnabled":!_vm.completedPreviously && _vm.taskCompletionConfirmed,"actionButtonBusy":_vm.actionButtonBusy,"buttonText":_vm.buttonText},on:{"handleTaskCTA":_vm.handleTaskCTA}})],1):_vm._e()],1)])],1)]:[_c('TaskPageSkeleton')]],2)}
var staticRenderFns = []

export { render, staticRenderFns }