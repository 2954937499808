<template>
  <BaseModal
    id="lives-impacted-modal"
    v-model="showModal"
    class="z-50 sm:h-3/5"
  >
    <template v-slot:title>
      <h3>Measuring Impact</h3>
    </template>
    <div class="text-left px-4 py-6">
      <h2 class="mb-2">
        How we measure impact
      </h2>
      <p class="mb-4">
        All team tasks have individual impact performance measures related to
        the impact generated once the task has been completed.
      </p>
      <p class="mb-4">
        ‘Lives Impacted’ is the overall impact performance measure used by Game
        of Our Lives Platform.
      </p>
      <h2 class="mb-2">
        What does “Lives Impacted” mean?
      </h2>
      <p class="mb-4">
        A life impacted should be viewed as the equivalent of a significant but
        temporary moment of support in someone’s day.
      </p>
      <p>
        For example
      </p>
      <ul class="mb-2 pl-8 list-disc">
        <li>
          1 Life Impacted = 1.00
        </li>
        <li>
          10 Lives Impacted = 10.00
        </li>
        <li>
          125.5 Lives Impacted = 125.50
        </li>
      </ul>
      <h2 class="mb-2">
        Where do these metrics come from?
      </h2>
      <p class="mb-4">
        All measures have been shaped using best-practice methods in
        collaboration with non-profit specialists and
        <a
          href="https://www.simetrica-jacobs.com/"
          target="_blank"
          class="text-blue-600"
          >Simetrica Jacobs</a
        >, a global leader in social value analysis. We have taken time and care
        to make sure these measures are as scientifically accurate as possible.
      </p>
      <h2 class="mb-2">
        How can you get accurate measures on social value?
      </h2>
      <p class="mb-4">
        Because we are measuring social value with many causes and geographic
        variables, we know it’s unrealistic to be absolutely accurate so we
        commit to continually improving to give ever closer indications of
        social value for every player on GOL.
      </p>

      <h2 class="mb-2">
        Why do you measure impact?
      </h2>
      <p class="mb-4">
        Our mission is to have 1 billion humans give 1% of their time to people
        and the planet by 2030, accelerating the goals of the United Nations
        Sustainable Development Goals. As a non-profit foundation existing for
        impact purposes, we love our impact measurement metrics running across
        our platform, they allow our community to get great estimations on the
        impact they have created, and through this gamified mechanic, inspire
        people to do more.
      </p>
      <p class="mb-4">
        <b>Questions?</b> We’d love to hear from you. Email us at
        <a href="mailto:admin@gameofourliv.es" class="text-blue-600"
          >admin@gameofourliv.es</a
        >
        ✌️🌎
      </p>
      <p class="mb-4">
        Find out more about Game of Our Lives’s impact mission at
        <a href="www.gameofourlives.org" target="_blank" class="text-blue-600"
          >www.gameofourlives.org</a
        >
      </p>
      <div class="grid grid-cols-2 gap-6">
        <img
          src="@/assets/img/logos/simetrica.png"
          alt="simetrica logo"
          class="m-auto"
        />
        <img
          src="@/assets/img/logos/football_for_the_goals.png"
          class="max-w-[50%] w-full"
          alt="football for the goals logo"
        />
      </div>
    </div>
  </BaseModal>
</template>

<script>
import defaultCurrency from "@/constants/defaultCurrency.js";

export default {
  name: "LivesImpactedInfoModal",
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      showModal: false
    };
  },
  watch: {
    showModal(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.showModal = val;
    }
  },
  computed: {
    defaultCurrency() {
      return defaultCurrency;
    }
  }
};
</script>
