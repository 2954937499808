<template>
  <div class="h-full">
    <template v-if="task">
      <div
        class="grid grid-cols-1 md:gap-4 md:mt-6"
        :class="{
          'md:grid-cols-2': showImage,
          'h-full': !showImage && !isDesktop
        }"
      >
        <BackButton
          v-if="isDesktop"
          id="back-button"
          @click="goBack()"
          :text="backButtonText"
          type="dark"
          class="col-span-2 w-auto mr-auto"
        />
        <div
          v-if="!isDesktop && !isStepCardEntry && !isStepGoneWrong"
          class="absolute top-0 w-full pt-10 z-10"
        >
          <div class="relative flex flex-row justify-between px-4">
            <BackButton v-if="isStepStart" @click="goBack()" />
            <CloseButton
              @click="
                $router.push({
                  name: 'TeamPage'
                })
              "
              type="white"
              :class="{ 'ml-auto': this.isStepComplete }"
            />
          </div>
        </div>
        <div v-if="showImage" class="main-image">
          <VideoRender
            :videoUrl="videoUrl"
            :coverImgUrl="imageUrl"
            class="md:rounded-md"
          >
            <div
              class="h-full bg-cover bg-center md:rounded-md"
              :style="`background-image: url('${imageUrl}');`"
            />
          </VideoRender>
        </div>
        <div
          class="main-info md:rounded-md"
          :class="{
            'bg-white': isDesktop,
            'desktop-single-pane-height': isDesktop && !showImage
          }"
        >
          <div
            class="flex flex-col p-4 md:p-10"
            :class="{
              'w-1/2 mx-auto': !showImage && isDesktop,
              'h-full': !showImage,
              'pb-0': !isDesktop
            }"
          >
            <template v-if="!isStepCardEntry">
              <h3
                class="block mb-2"
                :class="{ 'text-center': isStepComplete }"
                v-if="!isStepGoneWrong"
              >
                {{ heading }}
              </h3>
              <p
                v-if="content != ''"
                v-html="parsedContent(content)"
                class="mb-4 kentico-content"
                :class="{ 'text-center': isStepComplete }"
              ></p>
              <h4 class="text-center mb-2" v-if="isStepComplete">
                {{ $t("message.your-impact") }}
              </h4>
              <LivesImpactedPill
                v-if="!isStepGoneWrong || isStepComplete"
                :text="pillText"
                class="mb-4 mx-auto"
                :class="{
                  'order-first': !isStepComplete
                }"
              />
            </template>

            <template v-if="isStepStart">
              <label for="" class="block text-lg mb-2">{{
                $t("message.donation-amount")
              }}</label>
              <div class="grid grid-cols-2 gap-5">
                <div
                  v-for="(amountOption, index) in amountBoxes"
                  :key="`amount-${index}`"
                  class="flex items-center h-14 pl-2 rounded-sm border-2 border-solid"
                  :class="{
                    'bg-gray-placeholder border-gray-placeholder':
                      amountSelected !== amountOption.value,
                    'amount-box-active': amountSelected === amountOption.value
                  }"
                  @click="selectAmount(amountOption.value)"
                >
                  <input
                    type="radio"
                    :value="amountOption.value"
                    :id="`inputAmount-${index}`"
                    name="amount"
                    v-model="amountSelected"
                    class="h-6 w-6"
                  />
                  <label
                    :for="amountOption.name"
                    class="ml-2"
                    v-html="amountWithCurrency(amountOption.value)"
                  ></label>
                </div>
              </div>
              <InputWrapper
                v-model="amountInput"
                class="mt-5"
                name="amount"
                type="number"
                :isAmount="true"
                :error="hasError('amount')"
                :touched="touched('amount')"
                :hint="hint('amount')"
                @input="validateAmountForm()"
                @blur="changed('amount')"
                @focus="clearAmountSelected"
              />
            </template>

            <template v-else-if="isStepCardEntry">
              <div v-if="!isDesktop" class="relative flex flex-row mb-6">
                <BackButton @click="goBack()" type="dark" />
                <h3 class="m-auto">{{ $t("message.payment-details") }}</h3>
                <CloseButton
                  @click="
                    $router.push({
                      name: 'TeamPage'
                    })
                  "
                  type="black"
                />
              </div>
              <h4 class="text-center mb-8">
                {{ $t("message.youre-supporting", [task.beneficiary.name]) }}
              </h4>
              <!-- donation summary -->
              <div class="py-8 border-b border-t border-gray-300">
                <h3>{{ $t("message.donation-summary") }}</h3>
                <div class="mt-4 flex justify-between">
                  <label for="" class="text-lg">
                    {{ $t("message.donation-amount") }}
                  </label>
                  <p
                    class="inline-block"
                    v-html="amountWithCurrency(amount)"
                  ></p>
                </div>
              </div>
              <div class="pt-8">
                <h3>{{ $t("message.payment-details") }}</h3>
                <label for="" class="block mt-4">
                  {{ $t("message.payer-name") }}
                  <InputWrapper
                    v-model="payerNameInput"
                    class="mt-5"
                    name="payerName"
                    type="text"
                    :error="hasError('payerName')"
                    :touched="touched('payerName')"
                    :hint="hint('payerName')"
                    @input="validateNameAndEmailFields()"
                    @blur="changed('payerName')"
                  />
                </label>
                <label for="" class="block mt-4">
                  {{ $t("message.payer-email") }}
                  <InputWrapper
                    v-model="payerEmailInput"
                    class="mt-5"
                    name="payerEmail"
                    type="text"
                    :error="hasError('payerEmail')"
                    :touched="touched('payerEmail')"
                    :hint="hint('payerEmail')"
                    @input="validateNameAndEmailFields()"
                    @blur="changed('payerEmail')"
                  />
                </label>
                <label for="" class="block mt-4">
                  {{ $t("message.card-number") }}
                  <div id="card-number" class="card-input mb-4"></div>
                </label>
                <div class="grid grid-cols-2 gap-4">
                  <label>
                    {{ $t("message.cvv") }}
                    <div id="card-cvc" class="card-input"></div>
                  </label>

                  <label>
                    {{ $t("message.expiry-date") }}
                    <div id="expiry-date" class="card-input"></div>
                  </label>
                </div>
              </div>
              <TaskDonationModal v-model="showProcessingPaymentModal" />
            </template>

            <template v-else-if="isStepComplete">
              <TaskShare
                :shareImageUrl="metaData.imageUrl || imageUrl"
                class="mb-8"
              >
                <SocialShareGeneric
                  v-if="teamUrl && shareData"
                  class="mt-8 z-10"
                  :url="teamUrl"
                  :shareText="
                    kenticoTask.taskShareTitle !== ''
                      ? kenticoTask.taskShareTitle
                      : 'share'
                  "
                  :data="shareData"
                  :hashtags="
                    kenticoTask.taskShareHashtags !== ''
                      ? kenticoTask.taskShareHashtags
                      : 'share'
                  "
                  type="task-completed"
                />
              </TaskShare>
            </template>

            <SomethingsGoneWrong v-else-if="isStepGoneWrong" class="my-auto" />

            <div v-if="isStepCardEntry && cardErrors.length" class="mt-4">
              <p
                v-for="(error, index) in cardErrors"
                :key="`error${index}`"
                class="card-error"
              >
                {{ error.message }}
              </p>
            </div>
            <div v-if="isStepCardEntry && cardFormError" class="mt-4">
              <p class="card-error">
                {{ cardFormError }}
              </p>
            </div>
            <PaymentIcons v-if="showPaymentIcons" class="w-4/5 m-auto my-12" />
            <TaskCallToAction
              :buttonEnabled="true"
              :actionButtonBusy="actionButtonBusy"
              :buttonText="buttonText"
              @handleTaskCTA="handleTaskCTA"
              @blur="cardFormError = ''"
            />
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <TaskPageSkeleton />
    </template>
  </div>
</template>

<script>
import TaskPageSkeleton from "@/components/skeletons/TaskPageSkeleton.vue";
import BackButton from "@/components/shared/BackButton.vue";
import CloseButton from "@/components/shared/CloseButton.vue";
import LivesImpactedPill from "@/components/shared/LivesImpactedPill.vue";
import VideoRender from "@/components/shared/VideoRender.vue";
import PaymentIcons from "@/components/shared/PaymentIcons.vue";
import InputWrapper from "@/components/form/InputWrapper.vue";
import TaskDonationModal from "./TaskDonationModal.vue";
import SomethingsGoneWrong from "@/components/shared/SomethingsGoneWrong.vue";
import SocialShareMixin from "@/mixins/SocialShareMixin.js";
import TaskCallToAction from "./TaskCallToAction.vue";
import SocialShareGeneric from "@/components/posts/SocialShareGeneric.vue";
import TaskShare from "./TaskShare.vue";
import {
  GET_PAYMENT_INTENT,
  GET_PROFILE_FIELDS_FOR_DONATE_BILLING_DETAILS
} from "@/graphql/queries/user/userQueries.js";
import { TASK_ACTION_START_DONATION } from "@/graphql/queries/user/userMutations.js";
import { mapGetters } from "vuex";
import defaultCurrency from "@/constants/defaultCurrency.js";
import { Validator } from "@/utils/validateObject";

export default {
  name: "TaskDonation",
  mixins: [SocialShareMixin],
  components: {
    BackButton,
    CloseButton,
    LivesImpactedPill,
    VideoRender,
    InputWrapper,
    PaymentIcons,
    SomethingsGoneWrong,
    TaskPageSkeleton,
    TaskDonationModal,
    TaskCallToAction,
    SocialShareGeneric,
    TaskShare
  },
  props: {
    appsyncTask: {
      type: Object,
      required: true
    },
    kenticoTask: {
      type: Object,
      required: true
    },
    shareData: {
      type: Object,
      required: true
    },
    metaData: {
      type: Object,
      required: true
    },
    isMember: {
      type: Boolean,
      required: false
    },
    viewMode: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      actionButtonBusy: false,
      taskCompletionConfirmed: false,
      amountSelected: null,
      amountInput: "",
      payerNameInput: "",
      payerEmailInput: "",
      paymentIntent: {
        id: null,
        clientSecret: null
      },
      stripe: null,
      card: null,
      paymentFormStatus: "",
      paymentStep: "start", //complete",
      amountBoxes: [
        {
          value: 5,
          name: "amountFive"
        },
        {
          value: 10,
          name: "amountTen"
        },
        {
          value: 20,
          name: "amountTwenty"
        },
        {
          value: 50,
          name: "amountFifty"
        }
      ],
      cardInputCompletionStates: {},
      cardErrors: [],
      cardFormError: "",
      showProcessingPaymentModal: false,
      validation: {},
      pollStartTime: null,
      taskShareTitle: "I have finished this task, join us and make an impact",
      taskShareHashtags: ""
    };
  },
  computed: {
    ...mapGetters("general", ["isDesktop"]),
    ...mapGetters("auth", ["isLogged"]),
    task() {
      if (!this.appsyncTask || !this.kenticoTask) return null;
      return {
        ...this.appsyncTask,
        ...this.kenticoTask
      };
    },
    teamUrl() {
      if (!this.task?.team?.slug) return false;

      let props = this.$router.resolve({
        name: "TeamPage",
        params: {
          slug: this.task?.team?.slug || ""
        },
        query: {
          sharedFromTask: this.appsyncTask.kenticoCodename
        }
      });
      return process.env.VUE_APP_WEB_URL + props.href;
    },
    isStepStart() {
      return this.paymentStep === "start";
    },
    isStepCardEntry() {
      return this.paymentStep === "card-entry";
    },
    isStepGoneWrong() {
      return this.paymentStep === "somethings-gone-wrong";
    },
    isStepComplete() {
      return this.paymentStep === "complete";
    },
    imageUrl() {
      if (this.isStepStart) {
        return this.task.introImage?.url || "";
      } else if (this.isStepComplete) {
        return this.task.completeImage?.url || "";
      }
      return "";
    },
    /* shareImageUrl() {
      return this.task.coverImageUrl;
    }, */
    videoUrl() {
      if (this.isStepStart) {
        return this.task.introVideo?.url || "";
      } else if (this.isStepComplete) {
        return this.task.completeVideo?.url || "";
      }
      return "";
    },
    pillText() {
      if (!this.task) return "";
      else if (this.isStepStart)
        return `${defaultCurrency.pointsPerUnit.short} per ${defaultCurrency.symbol}`;
      else if (this.isStepComplete)
        return this.amount * defaultCurrency.pointsPerUnit.value;
      else return "";
    },
    heading() {
      if (this.isStepStart) return this.task.introHeading;
      else return this.task.completeHeading;
    },
    content() {
      if (this.isStepStart) return this.task.introContent.html;

      if (this.isStepGoneWrong) return "";

      return this.task.completeContent.html;
    },
    amount() {
      return this.amountSelected || this.amountInput;
    },
    buttonText() {
      if (this.isStepStart) {
        return this.$t("message.continue");
      } else if (this.isStepCardEntry) {
        return this.$t("message.donate-x", {
          symbol: defaultCurrency.htmlEntity,
          amount: this.amount
        });
      } else {
        return this.$t("message.done");
      }
    },
    backButtonText() {
      if (this.isStepStart) {
        return this.task.team.name;
      } else {
        return "Back";
      }
    },
    showImage() {
      return this.isStepStart || this.isStepComplete;
    },
    showPaymentIcons() {
      return this.isStepStart || this.isStepCardEntry;
    },
    touched() {
      return key => {
        return this.validation[key]?.touched == true;
      };
    },
    hasError() {
      return key => {
        return (
          (this.validation[key]?.error == true &&
            (this.showAllErrors == true ||
              this.validation[key]?.touched == true)) ||
          false
        );
      };
    },
    hint() {
      return key => {
        return this.validation[key]?.touched || this.showAllErrors
          ? this.validation[key]?.hint || ""
          : "";
      };
    }
  },
  apollo: {
    profile: {
      query: GET_PROFILE_FIELDS_FOR_DONATE_BILLING_DETAILS,
      update(data) {
        if (data.getMyProfile?.email) {
          this.payerEmailInput = data.getMyProfile.email;
        }
        if (data.getMyProfile?.displayName) {
          this.payerNameInput = data.getMyProfile.displayName;
        }
      },
      fetchPolicy: "no-cache",
      variables() {
        return {
          screenName: this.$store.getters["auth/currentUser"]?.attributes
            .screenName
        };
      },
      skip() {
        return !this.isLogged;
      }
    }
  },
  mounted() {
    this.includeStripe();
  },
  methods: {
    handleTaskCTA() {
      if (this.isLogged) {
        if (this.isStepStart) {
          if (this.isMember) {
            this.taskStartDonationTask();
          } else {
            this.$emit("showJoinTeam");
          }
        } else if (this.isStepCardEntry) {
          this.submitCardForm();
        } else {
          this.$router.push({ name: "TeamPage" });
        }
      } else {
        // redirect to login screen
        this.$router.push({ name: "SignInPage" });
      }
    },
    taskStartDonationTask() {
      if (!this.validateAmountForm()) {
        this.showAllErrors = true;
        this.$forceUpdate();
        return;
      }
      this.actionButtonBusy = true;
      this.$apollo
        .mutate({
          mutation: TASK_ACTION_START_DONATION,
          variables: {
            amount: this.amount * 100,
            currency: defaultCurrency.code,
            taskId: this.task.id,
            beneficiaryId: this.task.beneficiary.id
          },
          update: (_event, response) => {
            this.paymentIntent.id = response.data.taskActionStartDonation.id;
            this.paymentIntent.clientSecret =
              response.data.taskActionStartDonation.clientSecret;
            this.paymentStep = "card-entry";
            this.$nextTick(() => {
              this.prepareCardForm();
            });
          }
        })
        .then(() => {
          this.actionButtonBusy = false;
        })
        .catch(err => {
          this.handleStartDonationTaskErrors(err);
          this.actionButtonBusy = false;
        });
    },
    handleStartDonationTaskErrors(error) {
      if (!error.graphQLErrors || error.graphQLErrors.length == 0) {
        console.error(error);
        this.error = this.$t("message.graphql-request-failed");
        return;
      }
      this.error = this.$t(
        `message.appsync-errors.${error.graphQLErrors[0].errorType}`
      );
      error.graphQLErrors.forEach(err => {
        if (err.errorInfo) {
          err.errorInfo.forEach(field => {
            let value = field.errors[0].min || field.errors[0].max || null;
            if (field.fieldName === "amount") {
              value = this.amountWithCurrency(Number(value / 100));
            }
            let message = this.$t(
              `message.formValidation.${field.errors[0].type}`,
              { val: value }
            );
            this.validation[field.fieldName] = {
              error: true,
              hint: message
            };
          });
        }
      });
    },
    prepareCardForm() {
      this.cardErrors = [];
      let style = {
        base: {
          iconColor: "#666EE8",
          lineHeight: "56px",
          fontSize: "16px",
          "::placeholder": {
            color: "#CFD7E0"
          }
        }
      };
      let classes = {
        focus: "card-input-focus",
        invalid: "card-input-invalid"
      };

      this.stripe = window.Stripe(process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY);
      let elements = this.stripe.elements();
      // setup stripe elements
      this.cardNumber = elements.create("cardNumber", {
        style,
        classes,
        showIcon: true,
        placeholder: "Card number"
      });
      this.cardCvc = elements.create("cardCvc", {
        style,
        classes,
        placeholder: "CVC"
      });
      this.cardExpiry = elements.create("cardExpiry", {
        style,
        classes,
        placeholder: "MM/YY"
      });
      // mount
      this.cardNumber.mount("#card-number");
      this.cardCvc.mount("#card-cvc");
      this.cardExpiry.mount("#expiry-date");
      // add event listener
      this.cardCvc.on("change", event => {
        this.handleStripeEvent(event);
      });
      this.cardExpiry.on("change", event => {
        this.handleStripeEvent(event);
      });
      this.cardNumber.on("change", event => {
        this.handleStripeEvent(event);
      });

      /* this.paymentFormStatus = "Complete the form"; */
    },
    handleStripeEvent(event) {
      this.cardInputCompletionStates[event.elementType] = false;
      if (event.empty) {
        this.paymentFormStatus = "Complete the form";
      } else if (event.error) {
        this.paymentFormStatus = event.error.message;

        this.removeCardError(event);
        this.cardErrors.push({
          field: event.elementType,
          message: event.error.message
        });
      } else if (event.complete) {
        this.cardInputCompletionStates[event.elementType] = true;
        this.removeCardError(event);
      } else {
        this.paymentFormStatus = "";
        this.removeCardError(event);
      }
    },
    removeCardError(event) {
      let index = this.cardErrors.findIndex(
        error => error.field === event.elementType
      );
      if (index >= 0) {
        this.cardErrors.splice(index, 1);
      }
    },
    preventUnload(event) {
      if (this.showProcessingPaymentModal) {
        event.returnValue = "Payment in process";
      }
      return;
    },
    submitCardForm() {
      if (
        !this.validateNameAndEmailFields() ||
        !this.validateCreditCardForm()
      ) {
        this.showAllErrors = true;
        this.$forceUpdate();
        this.cardFormError = "Please complete the form";
        return;
      }
      this.showProcessingPaymentModal = true;
      this.actionButtonBusy = true;
      window.addEventListener("beforeunload", this.preventUnload);
      this.stripe
        .confirmCardPayment(this.paymentIntent.clientSecret, {
          payment_method: {
            card: this.cardNumber,
            billing_details: {
              name: this.payerNameInput,
              email: this.payerEmailInput
            }
          },
          receipt_email: this.payerEmailInput
        })
        .then(result => {
          if (result.error) {
            // Show error to your customer
            console.error(result.error.message);
            this.paymentFormStatus = result.error.message;
            this.cardErrors.push({
              field: result.error.type,
              message: result.error.message
            });
            this.showProcessingPaymentModal = false;
            this.actionButtonBusy = false;
          } else {
            // The payment succeeded!
            //  this.paymentStep = "poll-for-confirmation";
            this.paymentFormStatus =
              "Payment succeeded, now we can poll for confirmation.";
            this.pollForConfirmation();
          }
        })
        .catch(err => {
          console.error(err);
          this.actionButtonBusy = false;
        })
        .finally(() => {
          window.removeEventListener("beforeunload", this.preventUnload);
        });
    },
    pollForConfirmation() {
      if (!this.pollStartTime) this.pollStartTime = Date.now();
      console.log("polling...");
      this.actionButtonBusy = true;
      this.$apollo
        .query({
          query: GET_PAYMENT_INTENT,
          variables: {
            id: this.paymentIntent.id
          },
          fetchPolicy: "no-cache"
        })
        .then(response => {
          const status = response?.data?.getPaymentIntent?.status;
          console.log("status: ", status);
          if (status == "complete") {
            this.paymentFormStatus = "Payment Received, thank you!";
            this.paymentStep = "complete";
            console.log("hide processing modal");
            this.showProcessingPaymentModal = false;
            this.actionButtonBusy = false;
          } else {
            this.paymentFormStatus = "Payment not received yet...";
            if (this.pollingHasNotLapsed()) {
              setTimeout(() => {
                this.pollForConfirmation();
              }, 1000);
            } else {
              this.paymentStep = "somethings-gone-wrong";
              this.actionButtonBusy = false;
            }
          }
          console.log(this.paymentFormStatus);
        })
        .catch(err => {
          console.error(err);
          this.actionButtonBusy = false;
        });
    },
    parsedContent(src) {
      return src.replace(/\n/g, "<br />");
    },
    clearAmountSelected() {
      this.amountSelected = null;
    },
    goBack() {
      if (this.isStepStart) {
        // go back to team page
        this.$router.push({ name: "TeamPage" });
      } else {
        this.goBackToStart();
      }
    },
    goBackToStart() {
      this.paymentStep = "start";
      // reset all values
      this.pollStartTime = null;
      this.resetStartForm();
    },
    changed(key) {
      if (!this.validation[key]) {
        this.validation[key] = {
          touched: true
        };
      } else {
        if (this.validation[key].touched == null) {
          this.validation[key].touched = true;
        } else {
          this.validation[key] = { ...this.validation[key], touched: true };
        }
      }
      this.cardErrors = [];
      this.validateAmountForm();
      this.validateNameAndEmailFields();
      this.showAllErrors = true;
      this.$forceUpdate();
    },
    selectAmount(newAmount) {
      this.amountSelected = newAmount;
      this.clearError("amount");
    },
    addError(key, message) {
      if (this.validation[key]) {
        this.validation[key].error = true;
        this.validation[key].hint = message;
      } else {
        this.validation[key] = {
          hint: message,
          error: true
        };
      }
    },
    clearError(key) {
      if (this.validation[key]) {
        this.validation[key].error = false;
        this.validation[key].hint = "";
      }
    },
    resetStartForm() {
      this.amountInput = "";
      this.payerName = "";
      this.payerEmail = "";
      this.clearAmountSelected();
      this.validation = {};
      this.paymentFormStatus = "";
      this.cardInputCompletionStates = {};
    },
    validateAmountForm() {
      let result = true;
      this.clearError("amount");
      if (Validator.Number.isInvalid(this.amount)) {
        this.addError("amount", "Please enter an amount you wish to donate.");
        result = false;
      } else if (Validator.Number.isTooSmall(this.amount, 1)) {
        this.addError(
          "amount",
          `Please enter an amount greater than ${this.amountWithCurrency(1)}.`
        );
        result = false;
      } else if (Validator.Number.isTooBig(this.amount, 999_999)) {
        this.addError(
          "amount",
          `Please enter an amount less than ${this.amountWithCurrency(
            "1,000,000"
          )}.`
        );
        result = false;
      }
      return result;
    },
    validateNameAndEmailFields() {
      let result = true;
      this.clearError("payerName");
      this.clearError("payerEmail");
      if (Validator.String.isEmpty(this.payerNameInput)) {
        this.addError("payerName", this.$t("message.formValidation.empty"));
        result = false;
      } else if (Validator.String.isTooShort(this.payerNameInput, 2)) {
        this.addError(
          "payerName",
          this.$t("message.formValidation.too_short", { val: 2 })
        );
        result = false;
      } else if (Validator.String.isTooLong(this.payerNameInput, 255)) {
        this.addError(
          "payerName",
          this.$t("message.formValidation.too_long", { val: 255 })
        );
        result = false;
      }
      if (
        Validator.String.isEmpty(this.payerEmailInput) ||
        Validator.String.isInvalidEmailFormat(this.payerEmailInput)
      ) {
        this.addError(
          "payerEmail",
          this.$t("message.formValidation.invalid_email")
        );
        result = false;
      }
      return result;
    },
    validateCreditCardForm() {
      let result = false;
      if (
        this.cardInputCompletionStates.cardNumber &&
        this.cardInputCompletionStates.cardCvc &&
        this.cardInputCompletionStates.cardExpiry
      ) {
        this.cardErrors = [];
        this.cardFormError = "";
        result = true;
      }
      return result;
    },
    // check if 10 seconds have passed since pollForConfirmation was first executed
    pollingHasNotLapsed() {
      if (Date.now() - this.pollStartTime < 10000) return true;
      else false;
    },
    /*
        Includes Stripe.js dynamically
    */
    includeStripe() {
      let url = "js.stripe.com/v3/";
      // check if stripe has already been loaded
      if (document.querySelector(`script[src="//${url}"]`)) {
        return true;
      }
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];

      object.src = `//${url}`;
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    amountWithCurrency(value) {
      return defaultCurrency.htmlEntity + value;
    }
  },
  watch: {
    amountSelected(val) {
      if (val) this.amountInput = val.toString();
    }
  }
};
</script>

<style lang="postcss" scoped>
.main-image {
  height: 33rem;
}

.desktop-single-pane-height {
  min-height: 44rem;
}

.amount-box-active {
  background: white;
  border-color: #0175ff;
}

/* Firefox */
.amount-input input {
  appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
.amount-input input::-webkit-outer-spin-button,
.amount-input input::-webkit-inner-spin-button {
  appearance: none;
}

.amount-input:focus-within {
  border-color: #0175ff;

  @apply border-solid;
}

.amount-input input:focus-visible {
  outline: none;
}

.card-input {
  @apply mt-1 pl-4 border-2 rounded-sm;
}

.card-input-focus {
  border-color: #0175ff;
}

.card-input-invalid {
  border-color: #eb1c26;
}

.card-error {
  color: #eb1c26;
}

.bg-controls {
  width: 100%;
  height: 180px;
  top: 0;
  left: 0;
  position: absolute;
  background: linear-gradient(180deg, rgb(0 0 0 / 0%) 0%, #000 100%);
  transform: rotate(-180deg);
}

@media screen and (min-width: 768px) {
  .main-image {
    height: 561px;
  }
}
</style>
