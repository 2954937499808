import gql from "graphql-tag";
import { og } from "./sharedSnippets";

export const KENTICO_GET_ALL_TEAMS = gql`
  query team_All {
    team_All {
      items {
        _system_ {
          codename
        }
        title
        appsyncSlug
        imageDesktop {
          url
          name
          description
        }
        mainCaptain {
          name
        }
      }
    }
  }
`;

export const KENTICO_GET_TEAM_SHORT = gql`
  query team($codename: String!) {
    team(codename: $codename) {
      title
    }
  }
`;

export const KENTICO_GET_TEAM = gql`
  query team($codename: String!) {
    team(codename: $codename) {
      _system_ {
        codename
      }
      ${og}
      title
      appsyncSlug
      description {
        html
      }
      imageDesktop {
        url
        name
        description
      }
      video {
        url
        name
        description
      }
      tasks {
        items {
          _system_ {
            codename
          }
          title
          id
          coverImage {
            url
          }
          coverHeading
          coverContent
        }
      }
      mainCaptain {
        name
        avatar {
          url
        }
      }
      captains {
        items {
          _system_ {
            codename
          }
          name
          slug
          avatar {
            url
          }
        }
      }
      supporters {
        items {
          _system_ {
            codename
          }
          avatar {
            url
          }
          name
          slug
        }
      }
    }
  }
`;

export const KENTICO_GET_TEAM_AND_TASKS = gql`
  query teamAndTasks($teamCodename: String!) {
    team(codename: $teamCodename) {
      ${og}
      teamShareTitle
      pointsAwardedModifier
      timeGiven
      teamShareHashtags
      _system_ {
        codename
      }
      title
      appsyncSlug
      description {
        html
      }
      imageDesktop {
        url
        name
        description
      }
      video {
        url
        name
        description
      }
      videoThumbnail {
        url
      }
      status {
        items {
          _system_ {
            codename
            name
          }
        }
      }
      videoCaptions {
        items {
          file {
            url
          }
          label
          lang
        }
      }
      mainCaptain {
        name
        slug
        avatar {
          url
        }
      }
      captains {
        items {
          _system_ {
            codename
          }
          name
          slug
          avatar {
            url
          }
        }
      }
      supporters {
        items {
          _system_ {
            codename
          }
          name
          slug
          avatar {
            url
          }
        }
      }
      tasks {
        items {
          _system_{
            codename
          }
          title
          id
          coverImage {
            url
          }
          coverHeading
          coverContent
        }
      }
    }
  }
`;

export const KENTICO_GET_DISCOVER_TEAMS = gql`
  query discoverPage($codename: String!) {
    discoverPage(codename: $codename) {
      globalTimeGiven
      globalLivesImpacted
      teams {
        items {
          _badge {
            backgroundColor
            borderColor
            text
            textColor
            shape {
              items {
                _system_ {
                  name
                  codename
                }
              }
            }
            showAlways {
              items {
                _system_ {
                  codename
                  name
                }
              }
            }
            position {
              items {
                _system_ {
                  name
                  codename
                }
              }
            }
          }
          status {
            items {
              _system_ {
                codename
                name
              }
            }
          }
          appsyncSlug
          title
          mainCaptain {
            name
            slug
            avatar {
              url
            }
          }
          imageDesktop {
            url
          }
          imageMobile {
            url
          }
          placeholder {
            items {
              _system_ {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const KENTICO_GET_PROFILE_TEAMS_AND_TASKS = gql`
  query teamsAndTasks($teamsCodenames: [String!], $tasksCodenames: [String!], $includeTeams: Boolean!, $includeTasks: Boolean!) {
    team_All(
      limit: 40
      where: {
        AND: [
          { _system_: { codename: { in: $teamsCodenames } } }
          { _system_: { workflowStep: { notEq: "archived" } } }
        ]
      }
    ) @include(if: $includeTeams) {
      items {
        _system_ {
          codename
        }
        ${og}
        title
        appsyncSlug
        imageDesktop {
          url
          name
        }
      }
    }
    task_All(
      limit: 40
      where: {
        AND: [
          { _system_: { codename: { in: $tasksCodenames } } }
          { _system_: { workflowStep: { notEq: "archived" } } }
        ]
      }
    ) @include(if: $includeTasks) {
      items {
        _system_{
          codename
        }
        title
        id
        coverImage {
          url
        }
        coverHeading
        coverContent
      }
    }
  }
`;
