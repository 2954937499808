import axios from "axios";
const { VUE_APP_ZENDESK_DOMAIN } = process.env;
import Vue from "vue";

const zendeskConfig = {
  "gameofourlives.zendesk.com": {
    customFields: {
      clientVersion: 4720007817885,
      userAgent: 4720008974493,
      requestType: 4720020872605,
      requestUrl: 4719991501981,
      requestContext: 4719991971229
    }
  },
  "marinosoftwaresupport.zendesk.com": {
    customFields: {
      clientVersion: 4710338070813,
      userAgent: 4710364395805,
      requestType: 4710445328029,
      requestUrl: 4710716836765,
      requestContext: 4710907270301
    }
  }
};

// turn { customFieldName: customFieldValue } into [ { id: customFieldId, value: customFieldValue } ]
function customFields(params) {
  return Object.keys(params).map(key => {
    return {
      id: zendeskConfig[VUE_APP_ZENDESK_DOMAIN]?.customFields[key],
      value: params[key]
    };
  });
}

export function zendeskEnabled() {
  return !!(VUE_APP_ZENDESK_DOMAIN && zendeskConfig[VUE_APP_ZENDESK_DOMAIN]);
}

export function zendeskCreateRequest({
  name,
  email,
  subject,
  comment,
  type,
  requestType,
  requestContext
}) {
  return new Promise((resolve, reject) => {
    if (!zendeskEnabled()) {
      return reject(new Error("Zendesk not enabled!"));
    }
    // eslint-disable-next-line no-undef
    const describe = GIT_DESCRIBE;
    const clientVersion = `gol-appsync-vue-${describe.tag} (${describe.suffix})`;
    const userAgent = navigator.userAgent;
    const fields = {
      clientVersion,
      userAgent,
      requestType,
      requestUrl: location.href,
      requestContext: requestContext ? JSON.stringify(requestContext) : null
    };
    const requester = { name };
    if (email) {
      requester.email = email;
    }
    axios
      .post(`https://${VUE_APP_ZENDESK_DOMAIN}/api/v2/requests.json`, {
        request: {
          requester,
          subject,
          type,
          comment: { body: comment },
          custom_fields: customFields(fields)
        }
      })
      .then(() => {
        Vue.prototype.$analytics.sendSupportRequest(fields);
      })
      .then(data => resolve(data))
      .catch(err => reject(err));
  });
}
