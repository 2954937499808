<template>
  <div class="input-set">
    <div class="flex items-center mb-4">
      <Checkbox id="use-tweet-template" v-model="useTweetTemplate" />
      <label
        class="font-body ml-4 cursor-pointer text-sm"
        for="use-tweet-template"
      >
        {{ $t("message.use-tweet-template") }}
      </label>
      <div class="flex items-left text-sm">
        <nobr>{{
          $t("message.max-tweet-characters-status", {
            current: currentTweetTextLength,
            max: maxTweetContentLength
          })
        }}</nobr>
      </div>
    </div>
    <div
      class="flex items-center mb-4"
      v-if="tweetTemplates.length > 1 && useTweetTemplate"
    >
      <SelectInput
        :placeholder="$t('message.choose-one-select-placeholder')"
        :options="tweetTemplates"
        :value="tweetTemplates[tweetIndex].value"
        @input="onSelectedTemplateChanged"
        class="w-full"
      >
      </SelectInput>
    </div>
    <div class="flex items-center mb-4">
      <textarea
        v-model="tweetText"
        class="input textarea resize-none w-full h-full"
        :class="{
          'text-gray-700': useTweetTemplate,
          error: !formValid
        }"
        :disabled="useTweetTemplate"
      ></textarea>
    </div>
    <p class="hint mb-4 error" v-if="!formValid">
      {{ validationErrors[0] }}
    </p>
    <TaskCallToAction
      :buttonEnabled="formValid"
      :actionButtonBusy="actionButtonBusy"
      :buttonText="buttonText"
      @handleTaskCTA="sendTweet"
    />
  </div>
</template>

<script>
import TaskCallToAction from "./TaskCallToAction.vue";
import Checkbox from "@/components/form/Checkbox.vue";
import SelectInput from "@/components/form/SelectInput.vue";

const validationRules = {
  maxTweetContentLength: 280
};
const twitterTweetIntentUrl = "https://twitter.com/intent/tweet?text=";

export default {
  name: "TweetBox",
  components: {
    TaskCallToAction,
    Checkbox,
    SelectInput
  },
  props: {
    buttonText: {
      type: String,
      required: true
    },
    tweetTemplates: {
      type: Array, // [ { label: String, value: String } ]
      required: true
    },
    isLogged: {
      type: Boolean,
      required: false
    },
    isMember: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      actionButtonBusy: false,
      formValid: true,
      tweetText: null,
      customizedTweetTextSnapshot: null,
      useTweetTemplate: true,
      validationErrors: [],
      maxTweetContentLength: validationRules.maxTweetContentLength,
      currentTweetTextLength: -1,
      tweetIndex: 0
    };
  },
  created() {
    const text = this.normalizedTweetTemplate();
    this.tweetText = text;
    this.customizedTweetTextSnapshot = text;
    this.currentTweetTextLength = text.length;
  },
  watch: {
    tweetText(text) {
      this.formValid = this.validateTweetContent(text);
    },
    useTweetTemplate(boolean) {
      if (boolean) {
        this.customizedTweetTextSnapshot = this.tweetText;
        this.tweetText = this.normalizedTweetTemplate();
      } else {
        this.tweetText = this.customizedTweetTextSnapshot;
      }
    },
    tweetIndex() {
      if (this.useTweetTemplate) {
        this.tweetText = this.normalizedTweetTemplate();
      }
    }
  },
  methods: {
    sendTweet() {
      if (this.isLogged) {
        if (this.isMember) {
          this.actionButtonBusy = true;
          const sendTweetUrl =
            twitterTweetIntentUrl +
            this.htmlEncodeHashtags(this.normalizeTweetText(this.tweetText));
          window.open(sendTweetUrl);
          this.tweetSentEventHandler();
        } else {
          this.$emit("showJoinTeam");
        }
      } else {
        // redirect to login screen
        this.$router.push({ name: "SignInPage" });
      }
    },
    tweetSentEventHandler() {
      setTimeout(() => {
        this.actionButtonBusy = false;
        this.$emit("twitterOpened", { template: this.chosenTemplateName() });
      }, 1000);
    },
    validateTweetContent(content) {
      content = this.normalizeTweetText(content);
      this.currentTweetTextLength = content.length;
      this.validationErrors = [];
      if (content.length === 0) {
        this.validationErrors.push(
          `Max length ${validationRules.maxTweetContentLength}`
        );
      }
      if (content.length > validationRules.maxTweetContentLength) {
        this.validationErrors.push(
          `Max length ${validationRules.maxTweetContentLength}`
        );
      }
      return this.validationErrors.length === 0;
    },
    normalizeTweetText(content) {
      return content.trim();
    },
    htmlEncodeHashtags(content) {
      return content.replace(new RegExp(/#/, "g"), "%23");
    },
    normalizedTweetTemplate() {
      const templateText = this.tweetTemplates[this.tweetIndex].value;
      return this.normalizeTweetText(templateText);
    },
    onSelectedTemplateChanged(_value, selectedIndex) {
      this.tweetIndex = selectedIndex;
    },
    chosenTemplateName() {
      if (this.useTweetTemplate) {
        return this.tweetTemplates[this.tweetIndex].label;
      } else {
        return "custom";
      }
    }
  }
};
</script>

<style lang="postcss" scoped>
.input-set .input.textarea {
  height: 280px;
}
</style>
